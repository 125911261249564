import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Image } from "../components/site/Image";
import { Button } from "../components/site/Button";

const comingSoonImage = "/img/books/coming-soon-book-cover-100x130.png";

export const Book = (data) => {
  const {comingSoon} = data;
  const image = comingSoon ? comingSoonImage : data.image;
  return (
    <div className="col-md-6 col-lg-4 row p-3">
      <div className="d-flex flex-row" style={{ minHeight: "160px" }}>
        <div className="">
          <a
            href={data.amazonUrl}
            className={`${!data?.amazonUrl?.length ? "disabled" : ""}`}
          >
            <Image src={image} className={`rounded float-start gx-1`} />
          </a>
        </div>
        <div className="px-3">
          <h2 className="fw-bold text-uppercase">{data.title}</h2>
          <h3 className="fs-6">{data.subTitle}</h3>
          {!comingSoon && <div className="fs-6">
            <span className="fw-bold">
              {data.amazonPrice}
            </span>
          </div>}
        </div>
      </div>

      <div className="d-grid py-3 gap-2">
        <a
          href={!comingSoon && data?.amazonUrl?.length ? data.amazonUrl: '#'}       
          className={`btn btn-primary text-white ${
            comingSoon || !data?.amazonUrl?.length ? "disabled" : ""
          }`}
        >
          {!comingSoon && data?.amazonUrl?.length ? "Buy on Amazon" : "Coming Soon"}
        </a>
      </div>
    </div>
  );
};


export const sortTheBooks = (books) =>{
  return books?.sort((a,b)=>{
    return ((0||a?.node?.sortLevel) - (0||b?.node?.sortLevel))
  })
}


export const InnerBooks = (props)=>{
  console.log(props);
  const {comingSoon = true} = props;

  const books = props?.data?.allBooksJson?.edges;
  
  let sortedBooks = sortTheBooks(books)

  if(!comingSoon){
    console.log(sortedBooks);
    sortedBooks = sortedBooks.filter(book=>!book.node.comingSoon)
  }
  return (      <div className="container gx-1">
  
  <div className="d-flex flex-column flex-md-row flex-wrap ">
    {sortedBooks.map((book: any) =>
      book.node.published ? (
        <Book key={book.node.title} {...book.node} />
      ) : null
    )}
  </div>
</div>)
}


const Books = (props) => {
  // console.log(props);


  return (
    <Wrapper>
      <h1 className="fw-bold">Books</h1>
      <InnerBooks {...props} />
    </Wrapper>
  );
};

export const query = graphql`
  # query will go here
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          shortUrl
          published
          sortLevel
          comingSoon
          pages
        }
      }
    }
  }
`;

export default Books;
