import React, { useEffect } from 'react'
import clx from 'classnames'


interface BannerProps {
  className?: string
  style?: React.CSSProperties
  layout?: string
  format?: string
  client?: string
  responsive?: string
  slot: string
  layoutKey?: string
}

declare global {
  interface Window {
    adsbygoogle: any
  }
}

const Banner: React.FC<BannerProps> = ({
  className,
  style,
  layout,
  format,
  client = 'ca-pub-4767773038773066',
  slot,
  responsive,
  layoutKey,
}) => {
  useEffect(() => {
    // try {
    //   const adsbygoogle = window.adsbygoogle || []
    //   adsbygoogle.push({})
    // } catch (e) {
    //   console.error(e)
    // }

    if (typeof window === "undefined") return;

        const pushAd = () => {
          try {
            const adsbygoogle = window.adsbygoogle || [];
            adsbygoogle.push({});
          } catch (e) {
            console.error(e);
          }
        };
    
        let interval = setInterval(() => {
          // Check if Adsense script is loaded every 300ms
          if (window.adsbygoogle) {
            pushAd();
            // clear the interval once the ad is pushed so that function isn't called indefinitely
            clearInterval(interval);
          }
        }, 300);
    
        return () => {
          clearInterval(interval);
        };
  }, [])
  return (
    <div className={className}>
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-layout={layout}
        data-ad-format={format}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout-key={layoutKey}
        data-full-width-responsive={responsive}
      />
    </div>
  )
}

export default Banner