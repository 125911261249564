import books from './books';
import { graphql } from 'gatsby';

export const query = graphql`
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          shortUrl
          published
          sortLevel
          comingSoon
          pages
        }
      }
    }
  }
`;

/* Temporarily setup homepage to go books page */

export default books;

