import { useMediaQuery } from "@mantine/hooks";


const useMedia = ()=>{

    const is320 = useMediaQuery('(min-width: 320px)');
    const is475 = useMediaQuery('(min-width: 475px)');
    const is775 = useMediaQuery('(min-width: 775px)');
    const is975 = useMediaQuery('(min-width: 975px)');
    
    return {is320,is475, is775, is975}
}



export default  useMedia;