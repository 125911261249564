import React from "react";

export const Image = (props) => {
    const {
        as: Component = "img", className = `img-fluid`, additionalClasses, children, ...remaining
    } = props;
    return (
        <Component {...props} type="button" className={className || `${className} ${additionalClasses}`}>
            {children}
        </Component>
    );
};
