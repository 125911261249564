import React, { useState } from "react";
import { Link } from "gatsby";
import * as ROUTES from '../../constants/routes.constants';
import {Logo} from '../../components/site/Logo'
import { useLocation } from "@gatsbyjs/reach-router";
interface HeaderTabsProps {
  user: { name: string; image: string };
  tabs: string[];
}

export function MainHeader({...remaining}: HeaderTabsProps) {
  
  const [userMenuOpened, setUserMenuOpened] = useState(false);
const location = useLocation();

  const NavItem = ({
    children,
    to,
    className,
    additionalClasses = "",
    onClick = () => {
      setUserMenuOpened(false)
    },
    active = false,
    
  }) => (
    <li className="nav-item">
      <Link
        className={
          className || `nav-link ${additionalClasses} ${active ? "active" : ""}`
        }
        {...(active ? { ['aria-current']: "page" } : {})}
        to={to}
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );

  const isActive = (route:string)=> (route == location.pathname)

  return (
    <div >
      <nav className="navbar navbar-dark bg-dark fixed-top">
        <div className="container">
          <a
            className="navbar-brand text-uppercase text-uppercase fw-bold"
            href="/"
          >
            <Logo />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            onClick={() => setUserMenuOpened(true)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-dark"
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title text-uppercase fw-bold"
                id="offcanvasNavbarLabel"
              >
                <Logo />
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => setUserMenuOpened(false)}
              ></button>
            </div>
            <div className="offcanvas-body d-flex flex-column">
              {/* <form className="d-flex mb-3" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn text-white" type="submit">
                  Search
                </button>
              </form> */}
              <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">
                {/* <NavItem to={ROUTES.HOME} active={true}>
                  Home
                </NavItem> */}
                <NavItem to={ROUTES.BOOKS} active={isActive(ROUTES.BOOKS) || isActive(ROUTES.HOME)}>Books</NavItem>
                <NavItem to={ROUTES.KEYS} active={isActive(ROUTES.KEYS)}>Answer Keys</NavItem>
                <NavItem to={ROUTES.PUZZLES} active={isActive(ROUTES.PUZZLES)}>Puzzles</NavItem>
                

                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <NavItem to={ROUTES.ABOUT} additionalClasses="mb-auto" active={isActive(ROUTES.ABOUT)}>
                  About Us
                </NavItem>
                <NavItem to={ROUTES.CONTACT} active={isActive(ROUTES.CONTACT)} >Contact Us</NavItem>
                <NavItem to={ROUTES.PRIVACY} active={isActive(ROUTES.PRIVACY)} >Privacy Policy</NavItem>

                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
