import React, { ReactFragment, useState } from "react";
import { Link } from "@gatsbyjs/reach-router";

import Ad from "./Ad";
import styled from "styled-components";
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";
import "./Main.scss";
import * as ROUTES from "../../constants/routes.constants";
import netlifyIdentity from 'netlify-identity-widget';

import {
  AppShell,
  Breadcrumbs,
  Container,
  MediaQuery,
  Space,
  useMantineTheme,
} from "@mantine/core";
import { MainHeader } from "./MainHeader";

import { SideBarLeft } from "./SideBarLeft";
import { SideBarRight } from "./SideBarRight";
import { MainFooter } from "./MainFooter";
import useMedia from "../../hooks/useMedia";

const StyleWrapper = styled.div`
  background-color: #f8f9fa;
  /* max-width: 960px; */
  margin: auto;
  body {
    background-color: #f8f9fa;
  }
`;
const StyledAd = styled(Ad)`
  width: 400px;
  max-width: 100vw;

  height: 80px;
  display: inline-flex;
  justify-content: center;
`;

const Foot = styled.footer`
  position: relative;
`;
const FooterContent = styled.div`
  position: absolute;
  top: 0;
`;

const Main = styled.main`
  min-height: calc(100vh - 225px);
`;


const ContactModal = ()=>{
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name,
        email,
        message
      }),
    })
      .then(() => setSuccess(true))
      .catch((error) => alert(error));
  };


  return (      <div
    className="modal fade"
    id="contactModal"
    tabIndex="-1"
    aria-labelledby="contactModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
      
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="contactModalLabel">
            Contact Puzzlebase Games
          </h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
        {!success ? <form name="contact" netlify="true" method="post" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input type="text" name="name" className="form-control" value={name} onChange={(evt)=>setName(evt.target.value)}/>
            </div>
            <div>
              <label htmlFor="email" className="form-label">
                Email 
              </label>
              <input type="email" name="email" className="form-control" value={email} onChange={(evt)=>setEmail(evt.target.value)}/>
            </div>
            <div>
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea className="form-control" name="message" value={message} onChange={(evt)=>setMessage(evt.target.value)}></textarea>
            </div>
          
          <div className="my-3 text-align-end">
            <div className="d-flex align-items-center justify-content-between">
            <div>
          <button type="submit" className="btn btn-dark p-2" onClick={handleSubmit}>
            Send Message
          </button></div>
          <div>
          <Link to={ROUTES.PRIVACY} className=" p-2">
              Privacy Policy
            </Link>
          </div>
          </div>
          </div>
          </form> : 
            
            <div className="d-flex p-3 justify-content-center">Thank you</div>
            }
        </div>
        
      </div>
    </div>
  </div>)
}

if(typeof window !== 'undefined'){
  // @ts-ignore
  window.netlifyIdentity = netlifyIdentity;
  // You must run this once before trying to interact with the widget
  netlifyIdentity.init();
}

export const Wrapper: React.FC = ({ children, category = [], current }) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { is775, is975 } = useMedia();

  const items = [
    <div key="home">
      <Link to="/">Home</Link>
  </div>,
    ...category,
    <div key="current">{current}</div>,
  ];

return (
    <StyleWrapper>
      <ContactModal />
      <MainHeader />
      
      <Main className="bg-body my-5 py-3 container g-0">
      {children}


        
      </Main>
      <MediaQuery largerThan={500} styles={{ display: "none" }}>
        <>{!is775 && !is975 && <StyledAd style={{ margin: "5rem auto" }} />}</>
      </MediaQuery>

      <Foot className="container bg-light">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-md-row  flex-wrap py-3">
            <Link to={ROUTES.BOOKS} className="col-md-4">
              Books
            </Link>
            <Link to={ROUTES.KEYS} className="col-md-4">
              Answer Keys
            </Link>

            <Link to={ROUTES.PUZZLES} className="col-md-4">
              Puzzles
            </Link>

            <Link to={ROUTES.DOWNLOAD_APP} className="col-md-4">
              Downloads
            </Link>

            <Link to={ROUTES.ABOUT} className="col-md-4">
              About
            </Link>
            <Link to={ROUTES.CONTACT} className="col-md-4">
              Contact
            </Link>

            <Link to={ROUTES.PRIVACY} className="col-md-4">
              Privacy Policy
            </Link>
            {/* <Link to={ROUTES.LOGIN} className="col-md-4">
              Generator
            </Link> */}
          </div>

          <div>Copyright &copy; 2023 Puzzlebase Games LLC</div>
        </div>
      </Foot>
      {/* </AppShell> */}
    </StyleWrapper>
  );
};

export default Wrapper;
