export const KEYS= "/keys";
export const WORDSEARCH_KEYS = '/keys/wordsearch/'
export const HOME= "/";
export const BOOKS= "/books";
export const ABOUT= "/about";
export const PUZZLES= "/puzzles";
export const CONTACT= "/contact";
export const GENERATOR= "/generator";
export const PRIVACY= "/privacy";
export const DOWNLOAD_APP= "/download";
export const LOGIN= "/app/login";